/**
 * API call for the site management services
 */

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { map, Observable, tap } from 'rxjs'
import LoadEventsApiResponse, {
    GetLoadDetail,
    LoadById,
    LoadEvent,
    LoadEventList,
    LoadEventUpdate,
    LoadItem,
    LoadListApiResponse,
    LoadListResponse,
    ParamUpdateLoad,
    PermitApiResponse,
} from '@models/loads.model'
import { environment } from '@environment/environment'
import {
    CaseManagementAPIConstant,
    LoadManagementAPIConstant,
    TransportPermitsManagementAPIConstant,
} from 'src/app/constant/api.constants'
import { isString, SortSearchAndPaginationModal } from '@models/common.model'
import { CaseStatusButtonPillComponent } from '@shared/components/case-status-button-pill/case-status-button-pill.component'

@Injectable({
    providedIn: 'root',
})
export class LoadAPIService {
    constructor(private http: HttpClient) {}

    /** List **/
    getLoads(param: SortSearchAndPaginationModal): Observable<LoadListResponse> {
        const pageNumber = (param.pagination && param.pagination.currentPage) || 0
        const pageSize = (param.pagination && param.pagination.pageSize) || 20
        const sortField = param.pagination && param.pagination.sortField ? param.pagination.sortField : 'createdAt'
        const sortDir = param.pagination && param.pagination.sortDir ? param.pagination.sortDir : 'DESC'
        const queryString = isString(param.search) ? param.search : ''
        const siteId = param.filter && param.filter.siteId ? param.filter.siteId : ''
        const statusId = param.filter && param.filter.status ? this.mapStatusToEnum(param.filter.status) : ''
        const dateFrom = param.filter && param.filter.dateFrom ? param.filter.dateFrom : ''
        const dateTo = param.filter && param.filter.dateTo ? param.filter.dateTo : ''
        let queryPara: string = ''

        queryPara = `?sortDir=${sortDir}&pageNumber=${pageNumber}&pageSize=${pageSize}&queryString=${queryString?.toString()}&siteId=${siteId}&dateFrom=${dateFrom}&dateTo=${dateTo}`

        if (sortField) {
            queryPara += `&sortField=${sortField}`
        }

        if (statusId) {
            queryPara += `&statuses=${statusId}`
        }

        return this.http
            .get<LoadListApiResponse>(environment.apiBaseUrl + LoadManagementAPIConstant.LOADS + queryPara)
            .pipe(
                map((apiResponse: LoadListApiResponse) => {
                    const modifiedItems = apiResponse.items.map((item) => {
                        let mappedStatus
                        switch (item.status) {
                            case 'CREATED':
                                mappedStatus = 'Created'
                                break
                            case 'LOADING':
                                mappedStatus = 'Loading'
                                break
                            case 'IN_TRANSIT':
                                mappedStatus = 'In Transit'
                                break
                            case 'UN_LOADING':
                                mappedStatus = 'Unloading'
                                break
                            case 'CLOSED':
                                mappedStatus = 'Closed'
                                break
                            case 'EXCEPTION':
                                mappedStatus = 'Exception'
                                break
                            case 'CANCELED':
                                mappedStatus = 'Cancelled'
                                break
                            case 'SUSPENDED':
                                mappedStatus = 'Suspended'
                                break
                            case 'DISCARDED':
                                mappedStatus = 'Discarded'
                                break
                            default:
                                mappedStatus = 'Unknown'
                                break
                        }

                        return {
                            ...item,
                            status: mappedStatus,
                        }
                    })

                    return {
                        data: modifiedItems,
                        meta: {
                            itemCount: apiResponse.totalCount,
                            pageCount: apiResponse.totalPages,
                            currentPage: apiResponse.pageNumber,
                            pageSize: pageSize,
                        },
                    }
                })
            )
    }

    /**load details */
    getLoadDetail(param: GetLoadDetail): Observable<{ loads: LoadEventList }> {
        const loadId = param.id
        const processType = param.processType

        return this.http
            .get<LoadEventList>(
                environment.apiBaseUrl +
                    LoadManagementAPIConstant.LOADS +
                    '/' +
                    loadId +
                    '/events?processType=' +
                    processType
            )
            .pipe(
                map((apiResponse: LoadEventList) => {
                    return {
                        loads: apiResponse,
                    }
                })
            )
    }

    getLoadDetailByLoadId(param: GetLoadDetail): Observable<LoadEventsApiResponse> {
        const loadId = param.id
        const processType = param.processType

        return this.http
            .get<LoadEventsApiResponse>(
                environment.apiBaseUrl +
                    LoadManagementAPIConstant.LOADS +
                    '/' +
                    loadId +
                    '/events?processType=' +
                    processType
            )
            .pipe(
                map((apiResponse: LoadEventsApiResponse) => {
                    return apiResponse
                })
            )
    }

    getPermitDataByLoadId(permitId: number): Observable<PermitApiResponse> {
        return this.http
            .get<PermitApiResponse>(
                environment.apiBaseUrl + TransportPermitsManagementAPIConstant.ORDER + '/' + permitId
            )
            .pipe(
                map((apiResponse: PermitApiResponse) => {
                    return apiResponse
                })
            )
    }

    updateLoad(param: ParamUpdateLoad): Observable<boolean> {
        return this.http.post<boolean>(
            `${environment.apiBaseUrl}${CaseManagementAPIConstant.CASES}/${param.caseId}/loads/${param.loadId}`,
            {
                type: 'STATUS',
                prevValue: param.prevValue,
                nextValue: param.loadStatus,
                attributes: {},
            }
        )
    }

    /** get load detail from id */
    getLoadDetailById(param: LoadById): Observable<{ loadDetail: LoadItem }> {
        return this.http.get<LoadItem>(environment.apiBaseUrl + LoadManagementAPIConstant.LOADS + '/' + param.id).pipe(
            map((apiResponse: LoadItem) => {
                return {
                    loadDetail: apiResponse,
                }
            })
        )
    }

    private mapStatusToEnum(status: string): string {
        const statusMap: Record<string, string> = {
            Created: 'CREATED',
            Loading: 'LOADING',
            'In Transit': 'IN_TRANSIT',
            Unloading: 'UN_LOADING',
            Closed: 'CLOSED',
            Exception: 'EXCEPTION',
            Cancelled: 'CANCELED',
            Suspended: 'SUSPENDED',
            Discarded: 'DISCARDED',
        }

        return statusMap[status]
    }

    updateLoadEventValue(dto: LoadEventUpdate, caseId: string, loadId: string): Observable<LoadEvent> {
        const url = `${environment.apiBaseUrl}${CaseManagementAPIConstant.CASES}/${caseId}${LoadManagementAPIConstant.LOADS}/${loadId}`

        const body = dto

        return this.http.post<LoadEvent>(url, body).pipe(map((response) => response))
    }
}
