/**actions required for user list api */

import { ErrorInAPI, SortAndPaginationModal } from '@models/common.model'
import {
    GetCaseDetail,
    CaseById,
    CaseItem,
    CaseListResponse,
    CaseListSearch,
    CaseEventItem,
    CaseEventApiListResponse,
    ParamCaseEvents,
} from '@models/cases.model'

import { createAction, props } from '@ngrx/store'

export const loadCases = createAction(
    '[API] Get Cases',
    props<{
        pagination: SortAndPaginationModal
        search: string
    }>()
)

export const loadCaseWithSearch = createAction(
    '[API] Load Cases with search',
    props<{
        pagination: SortAndPaginationModal
        search: string
        filter?: CaseListSearch | {}
    }>()
)

export const loadCaseSuccess = createAction('[API] Get Cases Success', props<{ cases: CaseListResponse }>())

export const loadCaseError = createAction('[API] Get Cases Error', props<{ error: ErrorInAPI }>())

export const getCaseDetail = createAction('[API] Get Case Detail', props<GetCaseDetail>())

export const getCaseDetailSuccess = createAction(
    '[API] Get Case Detail Success',
    props<{
        case: any
    }>()
)

export const getCaseDetailFail = createAction(
    '[API] Get Case Detail Fail',
    props<{
        error: ErrorInAPI
    }>()
)

export const getCaseDetailById = createAction('[API] Get Case Detail By Id', props<CaseById>())

export const getCaseDetailByIdSuccess = createAction(
    '[API] Get Case Detail By Id Success ',
    props<{
        caseDetail: CaseItem
    }>()
)

export const getCaseDetailByIdFail = createAction(
    '[API] Get Case Detail By Id Fail',
    props<{
        error: ErrorInAPI
    }>()
)

export const resetStateAfterOperation = createAction('[API] Reset State After Operation')

export const resetStateSuccess = createAction('[API] Reset Case State Success')

export const updateCase = createAction(
    '[API] Update Case',
    props<{
        caseId: number
        quarantineSiteId: number
        responsibleParty: string
        caseStatus: string
        caseOutcome: string
        loadId?: number
    }>()
)

export const updateCaseSuccess = createAction('[API] Update Case Success')

export const updateCaseFailed = createAction('[API] Update Case Failed', props<{ error: ErrorInAPI }>())

export const getCaseEventFail = createAction('[API] Get Case Event Success Fail', props<{ error: ErrorInAPI }>())

export const getCaseEvents = createAction('[API] Get Case Events', props<ParamCaseEvents>())

export const getCaseEventsSuccess = createAction(
    '[API] Get Case Events Success',
    props<{ events: CaseEventApiListResponse }>()
)

export const getCaseEventsFail = createAction('[API] Get Case Events Failed', props<{ error: ErrorInAPI }>())

export const createCaseEvent = createAction(
    '[API] Create Case Events',
    props<{ content: string; eventType: string; id: number }>()
)

export const createCaseEventFailed = createAction(
    '[API] Create Case Events Success Fail',
    props<{ error: ErrorInAPI }>()
)

export const createCaseEventSuccess = createAction('[API] Create Case Events Success')

export const CaseActions = {
    loadCaseSuccess,
    getCaseDetail,
    getCaseDetailById,
    getCaseDetailByIdSuccess,
    getCaseDetailByIdFail,
    resetStateAfterOperation,
    resetStateSuccess,
    getCaseDetailSuccess,
    getCaseDetailFail,
    loadCaseError,
    loadCases,
    loadCaseWithSearch,
    updateCase,
    updateCaseSuccess,
    updateCaseFailed,
    getCaseEvents,
    getCaseEventsSuccess,
    getCaseEventsFail,
    createCaseEvent,
    createCaseEventSuccess,
    createCaseEventFailed,
}
